/* You can add global styles to this file, and also import other style files */

.body, .html {
  margin: O auto;
  padding: 0px
}

a {
  color: #1890ff;
  text-decoration: underline !important;
}
